.interest-certification {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 50px;
  margin-top: 50px;
  animation: slideInBottomTop 0.5s ease-in;
}

.interests > header,
.certifications > header {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 62px;
  color: var(--color-white);
}

.badge-wrap {
  margin: 16px 0;
}

.badge-wrap > img {
  width: 110px;
  height: auto;
}

@media (max-width: 768px) {
  .interest-certification {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .interests > header,
  .certifications > header {
    font-family: Lora;
    line-height: 36px;
  }
}
