.bio {
  width: 100%;
}

.bio > p {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-white);
  opacity: 0.7;
  white-space: normal;
  text-align: justify;
  animation: fadeIn 1s linear both;
}
