.menu-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  animation: slideInBottomTop50 0.2s ease forwards;
  padding-top: 77px;
  min-height: calc(100vh - 77px);
}

.menu-wrapper.menu-left {
  background: var(--color-cinder);
}

.menu-left > .app-menu {
  width: 100%;
  height: 100%;
  list-style-type: none;
  text-align: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  padding-bottom: 38px;
  padding-top: 40px;
}

.menu-left > .app-menu > li {
  margin: 30px 0;
  font-family: 'Lora';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 45px;
  color: var(--color-amethystSmoke);
  opacity: 0.3;
  cursor: pointer;
  text-align: center;
  animation: fadeIn 1s linear both;
  outline: none;
}

.app-menu > li.active {
  color: var(--color-white);
  opacity: 1;
  text-decoration: line-through;
  text-decoration-color: var(--color-cardinal);
  -webkit-text-decoration-color: var(--color-cardinal);
}

.menu-footer__mobile,
.menu-footer__desktop {
  flex-grow: 1;
  background-color: var(--color-bastille);
  padding: 0 24px;
}

.menu-footer__mobile > ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.menu-footer__mobile > ul > li > a {
  text-decoration: none;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 4px;
  color: var(--color-white);
  opacity: 0.7;
  text-transform: uppercase;
}

.menu-footer__desktop {
  display: none;
}

@media (min-width: 769px) {
  .menu-wrapper {
    flex-direction: row;
    flex-grow: unset;
    width: 100%;
    height: 100%;
    padding: unset;
  }

  .menu-footer__mobile {
    display: none;
  }

  .menu-footer__desktop {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .menu-left > .app-menu {
    height: 80%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .menu-left,
  .menu-footer__desktop {
    width: 50%;
  }

  .menu-left > .app-menu > li {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 80px;
    line-height: 113px;
    margin: 0;
  }

  .menu-footer__desktop {
    height: 100%;
  }

  .menu-footer__desktop > .wrapper {
    width: 30%;
    height: 80%;
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .menu-footer__desktop > .wrapper > .footer-center,
  .menu-footer__desktop > .wrapper > .right {
    margin-top: 60px;
    min-width: 300px;
  }

  .menu-footer-header__title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: var(--color-white);
    margin-top: 0;
    animation: fadeIn 0.3s linear both;
  }

  .menu-footer__desktop > .wrapper > .menu-socials {
    display: grid;
    grid-template-columns: repeat(3, minmax(70px, 1fr));
    gap: 0 20px;
    width: 200px;
  }

  .menu-footer__desktop > .wrapper > div > a,
  .footer-center > .menu-socials > li > a,
  .menu-footer__desktop > .wrapper > div > .download-cv {
    color: var(--color-white);
    opacity: 0.7;
    text-decoration: none;
    font-family: Raleway;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    animation: fadeIn 0.3s linear both;
    animation-delay: 0.1s;
  }

  .menu-footer__desktop > .wrapper > div > .menu-socials {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .menu-footer__desktop > .wrapper > div > ul > a {
    font-weight: 500;
  }

  .menu-footer__desktop > .wrapper > div > .download-cv {
    font-weight: bold;
  }
}
