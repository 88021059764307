.SectionB {
  width: 100%;
  height: 100%;
  background: var(--color-blueCinder);
  color: var(--color-white);
  overflow: auto;
}

.section-wrapper {
  padding-bottom: 80px;
}

.wrapper {
  flex-wrap: nowrap;
  align-items: flex-start;
}

.left-wrapper {
  margin-right: 90px;
  flex-grow: 1;
}

.right-wrapper {
  display: flex;
  justify-content: flex-end;
}

.introduction-wrapper {
  width: 100%;
}

.right-wrapper > img {
  animation: slideInLeftToRight 0.3s linear forwards;
  height: 500px;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 768px) {
  .SectionB {
    width: 100%;
    margin-top: 50px;
    height: unset;
    min-height: 100%;
  }

  .SectionB-content {
    max-width: 100%;
  }

  .wrapper {
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    padding-bottom: 20px;
  }

  .right-wrapper {
    display: none;
  }

  .left-wrapper {
    margin-right: unset;
    margin: 0 auto;
    width: 100%;
  }
}
