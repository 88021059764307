.SectionE {
  width: 100%;
  height: 100%;
  background: var(--color-white);
  color: var(--color-white);
  overflow: auto;
}

form {
  padding-left: 20px;
  background: var(--color-white);
  min-height: 60%;
  overflow: auto;
  flex-grow: 1;
}

.form-wrapper {
  width: 98%;
}

.form-input__wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(350px, 1fr));
  gap: 50px;
  flex-grow: 1;
  height: fit-content;
}

input,
textarea {
  border: none;
  border-bottom: 1px solid var(--color-gray);
  outline: none;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  animation: fadeIn 1s linear both;
}

.form-input__wrapper > input:first-child {
  animation-delay: 0.1s;
}

.form-input__wrapper > input:nth-child(2) {
  animation-delay: 0.2s;
}

.form-input__wrapper > input:nth-child(3) {
  animation-delay: 0.3s;
}

.form-input__wrapper > textarea {
  animation-delay: 0.4s;
}

input::placeholder,
textarea::placeholder {
  color: #1e1b27;
}

input:focus,
textarea:focus {
  border-bottom: 1px solid var(--color-blueCinder);
  transition: border-bottom 0.5s ease-out;
}

textarea {
  outline: none;
  line-height: unset;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  resize: vertical;
}

.submit-btn {
  position: relative;
  margin-left: 100px;
  margin-top: 20px;
  border-radius: 50%;
  height: 145px;
  width: 146px;
  font-size: 18px;
  font-weight: bold;
}

.submit-btn > span {
  position: absolute;
}

.footer {
  height: 188px;
  width: 100%;
  background-color: var(--color-cardinal);
  bottom: 0;
  padding: 0 50px;
  box-sizing: border-box;
  background-size: 100% 200%;
  background-image: linear-gradient(to bottom, var(--color-cardinal) 50%, var(--color-white) 50%);
  animation: backGroundSlideUp 0.3s linear forwards;
  margin-top: 30px;
}

.footer-header__title {
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: var(--color-white);
  margin-top: 0;
  animation: fadeIn 0.5s linear both;
  animation-delay: 0.6s;
}

.socials {
  display: grid;
  grid-template-columns: repeat(3, minmax(70px, 1fr));
  gap: 0 20px;
  width: 200px;
}

.footer > div > a,
.socials > li > a,
.footer > div > .download-cv {
  color: var(--color-pink);
  text-decoration: none;
  font-family: Raleway;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  animation: fadeIn 1s linear both;
  animation-delay: 0.7s;
}

.footer > div > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer > div > ul > a {
  font-weight: 500;
}

.footer > div > .download-cv {
  font-weight: bold;
}

@media (max-width: 768px) {
  .SectionE {
    width: 100%;
    padding-top: 50px;
    height: unset;
    min-height: 100%;
  }

  .SectionE-content {
    max-width: 100%;
  }

  .form {
    box-sizing: border-box;
    padding: left;
    padding: 30px 20px 61px 20px;
  }

  .form-wrapper {
    overflow: hidden;
  }

  .form-input__wrapper {
    grid-template-columns: 1fr;
    width: 90%;
  }

  .submit-btn {
    margin-left: unset;
    margin: 88px auto 0;
  }

  .footer-header__title {
    margin: 0;
  }

  .footer {
    box-sizing: border-box;
    flex-direction: column;
    height: unset;
    flex-wrap: nowrap;
    position: relative;
    align-items: flex-start;
    padding: 50px;
    padding-bottom: 100px;
  }

  .footer > .left,
  .footer > .footer-center,
  .footer > .footer-right {
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .submit-btn {
    margin-left: unset;
    margin: 88px auto 0;
  }
}
