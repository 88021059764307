.Work-card {
  width: 307px;
  height: 498px;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.Work-card:hover,
.Work-card:focus {
  animation: float 6s ease-in-out infinite;
}

.work-card__background {
  height: 342px;
  margin-top: 68px;
  position: relative;
}

.work-category {
  margin: 0;
  margin-top: 31px;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: var(--color-white);
  opacity: 0.7;
}

.work-title {
  margin-top: 10px;
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  color: var(--color-white);
  opacity: 0.8;
  margin: 0;
}

.work-image {
  position: absolute;
  top: 0;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.coming-soon {
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--color-cardinal);
  width: 135px;
  height: 36px;
}

.coming-soon > span {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  color: var(--color-white);
}

.secondary-card {
  width: 307px;
  height: 372px;
}

@media (max-width: 768px) {
  .Work-card,
  .secondary-card {
    margin: 0 auto;
    width: 100%;
  }
}
