.Section-a {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: var(--color-cinder);
  color: var(--color-white);
  background-image: url('../../images/vector-a.svg');
  background-position: -150px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: auto;
}

.content {
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
}

.hello {
  margin-top: 79px;
  margin-left: 60px;
  display: block;
  animation: slideInTopBottom 1s linear forwards;
}

.about-me__wrapper {
  margin-top: 50px;
  position: relative;
  align-items: center;
  flex-wrap: nowrap;
}

.about-me {
  white-space: break-spaces;
  padding: 0 62px;
  box-sizing: border-box;
  width: 85%;
  height: 100%;
  padding: 50px;
  background-color: var(--color-white);
  color: #000;
  align-items: flex-start;
  animation: slideInLeftRight 0.3s linear forwards;
}

.about-me > h1,
.dot {
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 8vw;
  color: var(--color-black);
  margin: 0;
  word-break: break-word;
}

.about-me > h1,
.about-me > p {
  animation: slideInBottomTop 1s linear forwards;
  width: 100%;
}

.dot {
  color: var(--color-cardinal);
}

.about-me > p {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--color-doveGray);
  margin: 0;
}

.scroll {
  width: 170px;
  padding: 60px 0;
  background-color: var(--color-cardinal);
  flex-grow: 1;
}

@media (max-width: 768px) {
  .Section-a {
    width: 100%;
    margin-top: 50px;
    height: unset;
    min-height: 100%;
  }

  .hello {
    margin-top: 80px;
  }

  .content {
    width: 100%;
    margin: unset;
    box-sizing: border-box;
    padding-left: 16px;
  }

  .about-me__wrapper {
    width: 100%;
    max-width: unset;
    flex-direction: column;
    align-items: flex-end;
  }

  .about-me {
    width: 100%;
    height: 160px;
    padding: 0 44px;
  }

  .about-me > h1,
  .dot {
    font-size: 40px;
  }

  .about-me > p {
    font-size: 16px;
  }

  .scroll {
    width: 126px;
    height: 142px;
  }
}
