.blog-card__wrapper {
  width: 100%;
  animation: fadeIn 1s linear both;
}

.blog-card {
  display: grid;
  grid-template-columns: 12% 0fr 1fr;
  grid-gap: 30px;
  gap: 30px;
  width: 100%;
  text-decoration: none;
}

.tick {
  width: 15px;
  height: 3px;
  background-color: var(--color-cardinal);
}

.date {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: var(--color-white);
  opacity: 0.7;
}

.blog-card__center {
  width: 152px;
  height: 100%;
}

.blog-card__center > img {
  object-fit: cover;
  object-position: center;
  width: 152px;
  height: 146px;
}

.blog-card__right {
  margin-top: 3px;
}

.blog-card__right > p {
  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  color: var(--color-white);
  margin: 0;
  white-space: normal;
}

.blog-card__underline {
  width: 100%;
  height: 1px;
  background-color: var(--color-mulledWine);
  margin: 0;
  margin-top: 24px;
  border: none;
}

.img-description {
  display: none;
}

@media (max-width: 768px) {
  .blog-card__wrapper {
    width: 100%;
  }

  .blog-card > .blog-card__center,
  .blog-card > .blog-card__right {
    display: none;
  }

  .blog-card {
    grid-template-columns: 60px 1fr;
  }

  .img-description {
    display: grid;
  }

  .blog-card__center > img {
    height: 77px;
    width: 80%;
  }
}
