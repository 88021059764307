.Section-header {
  position: relative;
  animation: slideInTopBottom 1s linear forwards;
}

.Section-header > h1 {
  font-weight: 900;
  font-size: 80px;
  line-height: 97px;
  color: var(--color-blackCurrant);
  margin: 0;
  word-spacing: spacing;
}

.Section-header > p {
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 62px;
  color: var(--color-white);
  position: absolute;
  left: 0;
  transform: translateY(-75px);
  margin: 0;
}

@media (max-width: 768px) {
  .Section-header {
    width: 100%;
  }

  .Section-header > h1 {
    font-size: 38px;
  }

  .Section-header > p {
    font-size: 24px;
    line-height: 36px;
    left: 40px;
    transform: translateY(-65px);
  }
}
