.Section-c {
  width: 100%;
  height: 100%;
  margin: auto;
  background: var(--color-cinder);
  color: var(--color-white);
  background-image: url('../../images/vector-c.svg');
  background-position: -150px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: auto;
}

.works {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  height: 100%;
  max-width: 100%;
  margin-top: 81px;
  justify-items: center;
  animation: slideInLeftToRight 0.5s ease-in-out both;
}

.works > div {
  height: fit-content;
}

.acel {
  margin-top: 60px;
}

@media (max-width: 768px) {
  .Section-c {
    width: 100%;
    padding-top: 50px;
    margin: unset;
    height: unset;
    min-height: 100%;
  }

  .works {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
  }

  .acel {
    margin-top: unset;
  }
}
