.App-header {
  width: 100%;
  background-color: var(--color-cornflowerBlue);
  min-height: 77px;
  color: var(--color-white);
  justify-content: flex-end;
  box-sizing: border-box;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 9999;
}

.menu {
  display: none;
}

.mobile {
  display: none;
}

@media (max-width: 768px) {
  .App-header {
    width: 100%;
    padding: 0 16px;
    background-color: var(--color-cinder);
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .links {
    display: none;
  }

  .menu {
    display: block;
  }
}
