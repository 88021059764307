.Layout {
  box-sizing: border-box;
  height: 100vh;
  transition: height 0.66s ease-out;
}

.Layout-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 120px;
  white-space: nowrap;
  transition: height 0.66s ease-out;

  cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png')
      39 39,
    auto;
  overflow-x: auto;
}

.Layout-wrapper > .component-wrapper {
  width: 100%;
  height: calc(100vh - 77px);
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-snap-type: none;
}

.Layout-wrapper > .component-wrapper > div {
  flex: 0 0 auto;
  scroll-snap-align: start;
}

@media (max-width: 768px) {
  .Layout {
    min-height: 100vh;
  }

  .Layout-wrapper {
    flex-direction: column;
    width: 100%;
    margin-left: unset;
  }

  .Layout-wrapper > .component-wrapper {
    width: 100%;
    flex-direction: column;
    overflow-y: auto;
    height: unset;
    scroll-snap-type: none;
  }
}
