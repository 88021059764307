.SectionA-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  bottom: 30px;
  margin-left: 62px;
  width: 90%;
  padding-top: 100px;
  box-sizing: border-box;
  animation: slideInBottomTop 1s linear forwards;
}

.trusted-by {
  font-family: Raleway;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  color: var(--color-white);
  opacity: 0.7;
  padding-left: 20px;
}

ul.footer-icons {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  align-items: center;
  gap: 20px;
}

.footer-icons > li {
  list-style-type: none;
  text-align: left;
}

@media (max-width: 768px) {
  .SectionA-footer {
    margin-left: unset;
    padding-left: 40px;
    width: 100%;
  }

  .trusted-by {
    padding-left: unset;
  }
}
