.Layout-sibar__container {
  width: 120px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  z-index: 9999;
  background-size: 100% 200%;
  background-image: linear-gradient(
    to bottom,
    var(--color-cornflowerBlue) 50%,
    var(--color-white) 50%
  );
  animation: backGroundSlideDown 0.5s linear forwards;
}

.Sidebar-app__logo {
  width: 100%;
  min-height: 77px;
  background: var(--color-bastille);
}

.Layout-side__bar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 51px 0;
}

svg {
  pointer-events: inherit;
}

.Layout-side__bar > .menu-btn {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button {
  cursor: pointer;
}

.mobile {
  display: none;
}

@media (max-width: 768px) {
  .Layout-sibar__container {
    display: none;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }
}
