.Interest-list {
  margin: 16px 0;
}

.logo-wrap {
  width: 44px;
  height: 44px;
  background-color: var(--color-cardinal);
  margin-right: 14px;
}

.Interest-list > span {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-white);
  animation: slideInBottomTop 0.5s ease forwards;
}
