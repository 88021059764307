.nav-items {
  display: flex;
  margin-right: calc(88px - 51px);
}

.nav-items > li {
  list-style-type: none;
  margin: 0 25.5px;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 4px;
  opacity: 0.7;
}

.nav-items > li > a {
  color: var(--color-white);
  text-decoration: none;
}
