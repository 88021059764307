@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
/**
 * Add the correct display in IE.
 */
main {
  display: block;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}
/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE.
 */
template {
  display: none;
}
/* User interaction
 * ========================================================================== */

*::-webkit-scrollbar {
  display: none;
}

:root {
  --color-cornflowerBlue: #171421;
  --color-cinder: #0f0e14;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-cardinal: #c9202d;
  --color-doveGray: #686868;
  --color-blueCinder: #17151e;
  --color-graySuit: #c6c2d1;
  --color-blackCurrant: #312c3f;
  --color-greenHaze: #00b82e;
  --color-bastille: #1e1b27;
  --color-mulledWine: #48415d;
  --color-gray: #8f8f8f;
  --color-pink: #ffccd0;
  --color-amethystSmoke: #aaa5c0;

  cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png')
      39 39,
    auto;
}

html,
body {
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -ms-overflow-style: none;
  -webkit-overflow-style: none;
  scrollbar-width: none;
}

body {
  margin: 0;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0f0e14;
  background-color: var(--color-cinder);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.center-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.justify-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.section-content-body {
  padding-top: 50px;
  margin: 0 auto;
  position: relative;
  max-width: 85%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

button {
  outline: none;
  background: none;
  border: none;
}

#scroll-arrow__icon {
  -webkit-animation: danceUpDown 1s linear infinite;
          animation: danceUpDown 1s linear infinite;
}

#scroll-icon-text,
.svg-send__btn-text {
  -webkit-animation: slideInBottomTop 0.3s linear forwards;
          animation: slideInBottomTop 0.3s linear forwards;
}

#works-icon__svg {
  -webkit-animation: bounce 2s ease-in-out forwards;
          animation: bounce 2s ease-in-out forwards;
}

#sidebar-menu {
  -webkit-animation: showHide 1s linear forwards;
          animation: showHide 1s linear forwards;
}

.svg-path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  -webkit-animation: dash 1s linear forwards;
          animation: dash 1s linear forwards;
}

.svg-view__all {
  -webkit-animation: slideInBottomTop50 0.2s linear forwards;
          animation: slideInBottomTop50 0.2s linear forwards;
}

@media (max-width: 768px) {
  .section-content-body {
    max-width: 90%;
    padding-top: unset;
  }

  .section-wrapper {
    padding: 0 20px;
    padding-bottom: unset !important;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .section-content-body {
    padding-top: unset;
  }
}

@-webkit-keyframes slideInLeftRight {
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 100%;
  }
}

@keyframes slideInLeftRight {
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 100%;
  }
}

@-webkit-keyframes scrollSlideInLeftRight {
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 170px;
  }
}

@keyframes scrollSlideInLeftRight {
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 170px;
  }
}

@-webkit-keyframes slideInBottomTop {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInBottomTop {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes slideInBottomTop50 {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInBottomTop50 {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes slideInTopBottom {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(10px);
  }
}

@keyframes slideInTopBottom {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(10px);
  }
}

@-webkit-keyframes backGroundSlideDown {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: 0% -100%;
  }
}

@keyframes backGroundSlideDown {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: 0% -100%;
  }
}

@-webkit-keyframes backGroundSlideUp {
  from {
    background-position: 0% -100%;
  }
  to {
    background-position: 0% 0%;
  }
}

@keyframes backGroundSlideUp {
  from {
    background-position: 0% -100%;
  }
  to {
    background-position: 0% 0%;
  }
}

@-webkit-keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes danceUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

@keyframes danceUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes showHide {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showHide {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes slideInLeftToRight {
  from {
    opacity: 0;
    transform: translateX(500px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInLeftToRight {
  from {
    opacity: 0;
    transform: translateX(500px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@-webkit-keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.nav-items {
  display: flex;
  margin-right: calc(88px - 51px);
}

.nav-items > li {
  list-style-type: none;
  margin: 0 25.5px;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 4px;
  opacity: 0.7;
}

.nav-items > li > a {
  color: var(--color-white);
  text-decoration: none;
}

.App-header {
  width: 100%;
  background-color: var(--color-cornflowerBlue);
  min-height: 77px;
  color: var(--color-white);
  justify-content: flex-end;
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 9999;
}

.menu {
  display: none;
}

.mobile {
  display: none;
}

@media (max-width: 768px) {
  .App-header {
    width: 100%;
    padding: 0 16px;
    background-color: var(--color-cinder);
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .links {
    display: none;
  }

  .menu {
    display: block;
  }
}

.svgCircle {
  transform-box: fill-box;
  transform-origin: 50% 50%;
  offset-distance: 4%;
  offset-path: path(
    'M1 5.75747C5.26087 11.5579 13 15.9748 26.5652 13.9747C42.3726 11.6441 59.5 -9.52527 85 7.69094'
  );
  will-change: offset-distance;
}

.Layout-sibar__container {
  width: 120px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  z-index: 9999;
  background-size: 100% 200%;
  background-image: linear-gradient(
    to bottom,
    var(--color-cornflowerBlue) 50%,
    var(--color-white) 50%
  );
  -webkit-animation: backGroundSlideDown 0.5s linear forwards;
          animation: backGroundSlideDown 0.5s linear forwards;
}

.Sidebar-app__logo {
  width: 100%;
  min-height: 77px;
  background: var(--color-bastille);
}

.Layout-side__bar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 51px 0;
}

svg {
  pointer-events: inherit;
}

.Layout-side__bar > .menu-btn {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button {
  cursor: pointer;
}

.mobile {
  display: none;
}

@media (max-width: 768px) {
  .Layout-sibar__container {
    display: none;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }
}

.menu-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  -webkit-animation: slideInBottomTop50 0.2s ease forwards;
          animation: slideInBottomTop50 0.2s ease forwards;
  padding-top: 77px;
  min-height: calc(100vh - 77px);
}

.menu-wrapper.menu-left {
  background: var(--color-cinder);
}

.menu-left > .app-menu {
  width: 100%;
  height: 100%;
  list-style-type: none;
  text-align: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  padding-bottom: 38px;
  padding-top: 40px;
}

.menu-left > .app-menu > li {
  margin: 30px 0;
  font-family: 'Lora';
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 45px;
  color: var(--color-amethystSmoke);
  opacity: 0.3;
  cursor: pointer;
  text-align: center;
  -webkit-animation: fadeIn 1s linear both;
          animation: fadeIn 1s linear both;
  outline: none;
}

.app-menu > li.active {
  color: var(--color-white);
  opacity: 1;
  text-decoration: line-through;
  text-decoration-color: var(--color-cardinal);
  -webkit-text-decoration-color: var(--color-cardinal);
}

.menu-footer__mobile,
.menu-footer__desktop {
  flex-grow: 1;
  background-color: var(--color-bastille);
  padding: 0 24px;
}

.menu-footer__mobile > ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.menu-footer__mobile > ul > li > a {
  text-decoration: none;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 4px;
  color: var(--color-white);
  opacity: 0.7;
  text-transform: uppercase;
}

.menu-footer__desktop {
  display: none;
}

@media (min-width: 769px) {
  .menu-wrapper {
    flex-direction: row;
    flex-grow: unset;
    width: 100%;
    height: 100%;
    padding: unset;
  }

  .menu-footer__mobile {
    display: none;
  }

  .menu-footer__desktop {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .menu-left > .app-menu {
    height: 80%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .menu-left,
  .menu-footer__desktop {
    width: 50%;
  }

  .menu-left > .app-menu > li {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 80px;
    line-height: 113px;
    margin: 0;
  }

  .menu-footer__desktop {
    height: 100%;
  }

  .menu-footer__desktop > .wrapper {
    width: 30%;
    height: 80%;
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .menu-footer__desktop > .wrapper > .footer-center,
  .menu-footer__desktop > .wrapper > .right {
    margin-top: 60px;
    min-width: 300px;
  }

  .menu-footer-header__title {
    font-family: Lora;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: var(--color-white);
    margin-top: 0;
    -webkit-animation: fadeIn 0.3s linear both;
            animation: fadeIn 0.3s linear both;
  }

  .menu-footer__desktop > .wrapper > .menu-socials {
    display: grid;
    grid-template-columns: repeat(3, minmax(70px, 1fr));
    grid-gap: 0 20px;
    gap: 0 20px;
    width: 200px;
  }

  .menu-footer__desktop > .wrapper > div > a,
  .footer-center > .menu-socials > li > a,
  .menu-footer__desktop > .wrapper > div > .download-cv {
    color: var(--color-white);
    opacity: 0.7;
    text-decoration: none;
    font-family: Raleway;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    -webkit-animation: fadeIn 0.3s linear both;
            animation: fadeIn 0.3s linear both;
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }

  .menu-footer__desktop > .wrapper > div > .menu-socials {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .menu-footer__desktop > .wrapper > div > ul > a {
    font-weight: 500;
  }

  .menu-footer__desktop > .wrapper > div > .download-cv {
    font-weight: bold;
  }
}

.Layout {
  box-sizing: border-box;
  height: 100vh;
  transition: height 0.66s ease-out;
}

.Layout-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 120px;
  white-space: nowrap;
  transition: height 0.66s ease-out;

  cursor: url('https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png')
      39 39,
    auto;
  overflow-x: auto;
}

.Layout-wrapper > .component-wrapper {
  width: 100%;
  height: calc(100vh - 77px);
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -ms-scroll-snap-type: none;
      scroll-snap-type: none;
}

.Layout-wrapper > .component-wrapper > div {
  flex: 0 0 auto;
  scroll-snap-align: start;
}

@media (max-width: 768px) {
  .Layout {
    min-height: 100vh;
  }

  .Layout-wrapper {
    flex-direction: column;
    width: 100%;
    margin-left: unset;
  }

  .Layout-wrapper > .component-wrapper {
    width: 100%;
    flex-direction: column;
    overflow-y: auto;
    height: unset;
    -ms-scroll-snap-type: none;
        scroll-snap-type: none;
  }
}

.SectionA-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  bottom: 30px;
  margin-left: 62px;
  width: 90%;
  padding-top: 100px;
  box-sizing: border-box;
  -webkit-animation: slideInBottomTop 1s linear forwards;
          animation: slideInBottomTop 1s linear forwards;
}

.trusted-by {
  font-family: Raleway;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  color: var(--color-white);
  opacity: 0.7;
  padding-left: 20px;
}

ul.footer-icons {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.footer-icons > li {
  list-style-type: none;
  text-align: left;
}

@media (max-width: 768px) {
  .SectionA-footer {
    margin-left: unset;
    padding-left: 40px;
    width: 100%;
  }

  .trusted-by {
    padding-left: unset;
  }
}

.Section-a {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: var(--color-cinder);
  color: var(--color-white);
  background-image: url(/static/media/vector-a.c45ed7be.svg);
  background-position: -150px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: auto;
}

.content {
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
}

.hello {
  margin-top: 79px;
  margin-left: 60px;
  display: block;
  -webkit-animation: slideInTopBottom 1s linear forwards;
          animation: slideInTopBottom 1s linear forwards;
}

.about-me__wrapper {
  margin-top: 50px;
  position: relative;
  align-items: center;
  flex-wrap: nowrap;
}

.about-me {
  white-space: break-spaces;
  padding: 0 62px;
  box-sizing: border-box;
  width: 85%;
  height: 100%;
  padding: 50px;
  background-color: var(--color-white);
  color: #000;
  align-items: flex-start;
  -webkit-animation: slideInLeftRight 0.3s linear forwards;
          animation: slideInLeftRight 0.3s linear forwards;
}

.about-me > h1,
.dot {
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 8vw;
  color: var(--color-black);
  margin: 0;
  word-break: break-word;
}

.about-me > h1,
.about-me > p {
  -webkit-animation: slideInBottomTop 1s linear forwards;
          animation: slideInBottomTop 1s linear forwards;
  width: 100%;
}

.dot {
  color: var(--color-cardinal);
}

.about-me > p {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: var(--color-doveGray);
  margin: 0;
}

.scroll {
  width: 170px;
  padding: 60px 0;
  background-color: var(--color-cardinal);
  flex-grow: 1;
}

@media (max-width: 768px) {
  .Section-a {
    width: 100%;
    margin-top: 50px;
    height: unset;
    min-height: 100%;
  }

  .hello {
    margin-top: 80px;
  }

  .content {
    width: 100%;
    margin: unset;
    box-sizing: border-box;
    padding-left: 16px;
  }

  .about-me__wrapper {
    width: 100%;
    max-width: unset;
    flex-direction: column;
    align-items: flex-end;
  }

  .about-me {
    width: 100%;
    height: 160px;
    padding: 0 44px;
  }

  .about-me > h1,
  .dot {
    font-size: 40px;
  }

  .about-me > p {
    font-size: 16px;
  }

  .scroll {
    width: 126px;
    height: 142px;
  }
}

.Section-header {
  position: relative;
  -webkit-animation: slideInTopBottom 1s linear forwards;
          animation: slideInTopBottom 1s linear forwards;
}

.Section-header > h1 {
  font-weight: 900;
  font-size: 80px;
  line-height: 97px;
  color: var(--color-blackCurrant);
  margin: 0;
  word-spacing: spacing;
}

.Section-header > p {
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 62px;
  color: var(--color-white);
  position: absolute;
  left: 0;
  transform: translateY(-75px);
  margin: 0;
}

@media (max-width: 768px) {
  .Section-header {
    width: 100%;
  }

  .Section-header > h1 {
    font-size: 38px;
  }

  .Section-header > p {
    font-size: 24px;
    line-height: 36px;
    left: 40px;
    transform: translateY(-65px);
  }
}

.bio {
  width: 100%;
}

.bio > p {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-white);
  opacity: 0.7;
  white-space: normal;
  text-align: justify;
  -webkit-animation: fadeIn 1s linear both;
          animation: fadeIn 1s linear both;
}

.interest-certification {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 50px;
  gap: 50px;
  margin-top: 50px;
  -webkit-animation: slideInBottomTop 0.5s ease-in;
          animation: slideInBottomTop 0.5s ease-in;
}

.interests > header,
.certifications > header {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 62px;
  color: var(--color-white);
}

.badge-wrap {
  margin: 16px 0;
}

.badge-wrap > img {
  width: 110px;
  height: auto;
}

@media (max-width: 768px) {
  .interest-certification {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .interests > header,
  .certifications > header {
    font-family: Lora;
    line-height: 36px;
  }
}

.Interest-list {
  margin: 16px 0;
}

.logo-wrap {
  width: 44px;
  height: 44px;
  background-color: var(--color-cardinal);
  margin-right: 14px;
}

.Interest-list > span {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-white);
  -webkit-animation: slideInBottomTop 0.5s ease forwards;
          animation: slideInBottomTop 0.5s ease forwards;
}

.SectionB {
  width: 100%;
  height: 100%;
  background: var(--color-blueCinder);
  color: var(--color-white);
  overflow: auto;
}

.section-wrapper {
  padding-bottom: 80px;
}

.wrapper {
  flex-wrap: nowrap;
  align-items: flex-start;
}

.left-wrapper {
  margin-right: 90px;
  flex-grow: 1;
}

.right-wrapper {
  display: flex;
  justify-content: flex-end;
}

.introduction-wrapper {
  width: 100%;
}

.right-wrapper > img {
  -webkit-animation: slideInLeftToRight 0.3s linear forwards;
          animation: slideInLeftToRight 0.3s linear forwards;
  height: 500px;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 768px) {
  .SectionB {
    width: 100%;
    margin-top: 50px;
    height: unset;
    min-height: 100%;
  }

  .SectionB-content {
    max-width: 100%;
  }

  .wrapper {
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    padding-bottom: 20px;
  }

  .right-wrapper {
    display: none;
  }

  .left-wrapper {
    margin-right: unset;
    margin: 0 auto;
    width: 100%;
  }
}

.Work-card {
  width: 307px;
  height: 498px;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.Work-card:hover,
.Work-card:focus {
  -webkit-animation: float 6s ease-in-out infinite;
          animation: float 6s ease-in-out infinite;
}

.work-card__background {
  height: 342px;
  margin-top: 68px;
  position: relative;
}

.work-category {
  margin: 0;
  margin-top: 31px;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: var(--color-white);
  opacity: 0.7;
}

.work-title {
  margin-top: 10px;
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  color: var(--color-white);
  opacity: 0.8;
  margin: 0;
}

.work-image {
  position: absolute;
  top: 0;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.coming-soon {
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--color-cardinal);
  width: 135px;
  height: 36px;
}

.coming-soon > span {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  color: var(--color-white);
}

.secondary-card {
  width: 307px;
  height: 372px;
}

@media (max-width: 768px) {
  .Work-card,
  .secondary-card {
    margin: 0 auto;
    width: 100%;
  }
}

.Section-c {
  width: 100%;
  height: 100%;
  margin: auto;
  background: var(--color-cinder);
  color: var(--color-white);
  background-image: url(/static/media/vector-c.ae0534a7.svg);
  background-position: -150px;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: auto;
}

.works {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  gap: 20px;
  height: 100%;
  max-width: 100%;
  margin-top: 81px;
  justify-items: center;
  -webkit-animation: slideInLeftToRight 0.5s ease-in-out both;
          animation: slideInLeftToRight 0.5s ease-in-out both;
}

.works > div {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.acel {
  margin-top: 60px;
}

@media (max-width: 768px) {
  .Section-c {
    width: 100%;
    padding-top: 50px;
    margin: unset;
    height: unset;
    min-height: 100%;
  }

  .works {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
  }

  .acel {
    margin-top: unset;
  }
}

.blog-card__wrapper {
  width: 100%;
  -webkit-animation: fadeIn 1s linear both;
          animation: fadeIn 1s linear both;
}

.blog-card {
  display: grid;
  grid-template-columns: 12% 0fr 1fr;
  grid-gap: 30px;
  grid-gap: 30px;
  gap: 30px;
  width: 100%;
  text-decoration: none;
}

.tick {
  width: 15px;
  height: 3px;
  background-color: var(--color-cardinal);
}

.date {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: var(--color-white);
  opacity: 0.7;
}

.blog-card__center {
  width: 152px;
  height: 100%;
}

.blog-card__center > img {
  object-fit: cover;
  object-position: center;
  width: 152px;
  height: 146px;
}

.blog-card__right {
  margin-top: 3px;
}

.blog-card__right > p {
  font-family: Lora;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  color: var(--color-white);
  margin: 0;
  white-space: normal;
}

.blog-card__underline {
  width: 100%;
  height: 1px;
  background-color: var(--color-mulledWine);
  margin: 0;
  margin-top: 24px;
  border: none;
}

.img-description {
  display: none;
}

@media (max-width: 768px) {
  .blog-card__wrapper {
    width: 100%;
  }

  .blog-card > .blog-card__center,
  .blog-card > .blog-card__right {
    display: none;
  }

  .blog-card {
    grid-template-columns: 60px 1fr;
  }

  .img-description {
    display: grid;
  }

  .blog-card__center > img {
    height: 77px;
    width: 80%;
  }
}

.app-loader {
  -webkit-animation: bounce 2s ease-in-out infinite;
          animation: bounce 2s ease-in-out infinite;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.app-loader-path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}

.app-loader-path {
  -webkit-animation: dash 2s ease-in-out infinite;
          animation: dash 2s ease-in-out infinite;
}

.SectionD {
  width: 100%;
  height: 100%;
  background: var(--color-bastille);
  color: var(--color-white);
  overflow: auto;
}

.blogs {
  margin-top: 63px;
  width: 100%;
  margin-top: 63px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  justify-content: space-between;
  grid-gap: 50px 200px;
  gap: 50px 200px;
  height: auto;
}

.view-all {
  text-decoration: none;
  margin: 43px auto 0;
  /* padding-bottom: 100px; */
}

@media (max-width: 768px) {
  .SectionD {
    width: 100%;
    height: unset;
    min-height: 100%;
    padding-top: 50px;
  }

  .blogs {
    grid-template-columns: repeat(1, 90%);
    justify-content: center;
  }

  .view-all {
    padding-bottom: 50px;
  }
}

.svg-send__path {
  stroke-dasharray: 500;
  stroke-width: 3;
  transition: all 0.5s ease-out;
}

.svg-send__path {
  -webkit-animation: dash 2s ease-in-out infinite;
          animation: dash 2s ease-in-out infinite;
}

.SectionE {
  width: 100%;
  height: 100%;
  background: var(--color-white);
  color: var(--color-white);
  overflow: auto;
}

form {
  padding-left: 20px;
  background: var(--color-white);
  min-height: 60%;
  overflow: auto;
  flex-grow: 1;
}

.form-wrapper {
  width: 98%;
}

.form-input__wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(350px, 1fr));
  grid-gap: 50px;
  gap: 50px;
  flex-grow: 1;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

input,
textarea {
  border: none;
  border-bottom: 1px solid var(--color-gray);
  outline: none;
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  -webkit-animation: fadeIn 1s linear both;
          animation: fadeIn 1s linear both;
}

.form-input__wrapper > input:first-child {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.form-input__wrapper > input:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.form-input__wrapper > input:nth-child(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.form-input__wrapper > textarea {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #1e1b27;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #1e1b27;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  color: #1e1b27;
}

input::placeholder,
textarea::placeholder {
  color: #1e1b27;
}

input:focus,
textarea:focus {
  border-bottom: 1px solid var(--color-blueCinder);
  transition: border-bottom 0.5s ease-out;
}

textarea {
  outline: none;
  line-height: unset;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  resize: vertical;
}

.submit-btn {
  position: relative;
  margin-left: 100px;
  margin-top: 20px;
  border-radius: 50%;
  height: 145px;
  width: 146px;
  font-size: 18px;
  font-weight: bold;
}

.submit-btn > span {
  position: absolute;
}

.footer {
  height: 188px;
  width: 100%;
  background-color: var(--color-cardinal);
  bottom: 0;
  padding: 0 50px;
  box-sizing: border-box;
  background-size: 100% 200%;
  background-image: linear-gradient(to bottom, var(--color-cardinal) 50%, var(--color-white) 50%);
  -webkit-animation: backGroundSlideUp 0.3s linear forwards;
          animation: backGroundSlideUp 0.3s linear forwards;
  margin-top: 30px;
}

.footer-header__title {
  font-family: Lora;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: var(--color-white);
  margin-top: 0;
  -webkit-animation: fadeIn 0.5s linear both;
          animation: fadeIn 0.5s linear both;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.socials {
  display: grid;
  grid-template-columns: repeat(3, minmax(70px, 1fr));
  grid-gap: 0 20px;
  gap: 0 20px;
  width: 200px;
}

.footer > div > a,
.socials > li > a,
.footer > div > .download-cv {
  color: var(--color-pink);
  text-decoration: none;
  font-family: Raleway;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  -webkit-animation: fadeIn 1s linear both;
          animation: fadeIn 1s linear both;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}

.footer > div > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer > div > ul > a {
  font-weight: 500;
}

.footer > div > .download-cv {
  font-weight: bold;
}

@media (max-width: 768px) {
  .SectionE {
    width: 100%;
    padding-top: 50px;
    height: unset;
    min-height: 100%;
  }

  .SectionE-content {
    max-width: 100%;
  }

  .form {
    box-sizing: border-box;
    padding: left;
    padding: 30px 20px 61px 20px;
  }

  .form-wrapper {
    overflow: hidden;
  }

  .form-input__wrapper {
    grid-template-columns: 1fr;
    width: 90%;
  }

  .submit-btn {
    margin-left: unset;
    margin: 88px auto 0;
  }

  .footer-header__title {
    margin: 0;
  }

  .footer {
    box-sizing: border-box;
    flex-direction: column;
    height: unset;
    flex-wrap: nowrap;
    position: relative;
    align-items: flex-start;
    padding: 50px;
    padding-bottom: 100px;
  }

  .footer > .left,
  .footer > .footer-center,
  .footer > .footer-right {
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .submit-btn {
    margin-left: unset;
    margin: 88px auto 0;
  }
}

