.SectionD {
  width: 100%;
  height: 100%;
  background: var(--color-bastille);
  color: var(--color-white);
  overflow: auto;
}

.blogs {
  margin-top: 63px;
  width: 100%;
  margin-top: 63px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  justify-content: space-between;
  gap: 50px 200px;
  height: auto;
}

.view-all {
  text-decoration: none;
  margin: 43px auto 0;
  /* padding-bottom: 100px; */
}

@media (max-width: 768px) {
  .SectionD {
    width: 100%;
    height: unset;
    min-height: 100%;
    padding-top: 50px;
  }

  .blogs {
    grid-template-columns: repeat(1, 90%);
    justify-content: center;
  }

  .view-all {
    padding-bottom: 50px;
  }
}
